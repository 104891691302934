import React, { useState, useEffect, useRef } from "react";
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import {
  Chart,
  ChartArea,
  ChartTooltip,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTitle,
  ChartCategoryAxis,
  ChartCategoryAxisTitle,
  ChartCategoryAxisItem
} from "@progress/kendo-react-charts";
import "hammerjs";
import { formatDate } from '@telerik/kendo-intl';
import { Redirect } from "react-router-dom";
import Form from "react-validation/build/form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import AuthService from "../services/auth.service";
import QueryService from "../services/query.service";
import { CellRender, RowRender } from "../components/CellRender";
import { ShowMoreCell } from "../components/ShowMoreCell";
import "../App.css";

const dallasForms = [['all','All Forms'],
                     ['19', 'Ad Page: Blue Cross Blue Shield'], 
                     ['20', 'Ad Page: Online Therapy'],
                     ['15', 'Christian Counseling in Dallas'],
                     ['16', 'Contact Us — Contact Us Page'],
                     ['3',  'Contact Us — Footer'],
                     ['12', 'Couples Therapy in Dallas'],
                     ['13', 'Individual Therapy in Dallas'],
                     ['17', 'Individual Therapy in Plano (2019-10-07)'],
                     ['14','Medicare Counseling in Dallas'],
                     ['21', 'Phone/Video Counseling Signup'],
                     ['18', 'Teen Counseling (2019-11-19)']];
                     
const planoForms = [['all','All Forms'],
                    ['p-4', 'Plano Landing Page Contact Form'], 
                    ['p-13', 'Plano Phone/Video Counseling Signup']];

const allForms = [['all','All Forms']];                   


const validateLeadStatus = ( value ) => {
  if ( value === "not applicable" || value === "web form" || value === "converted lead"){
    return true;
  }
  return false;
}

const cellWithBackGround = (props) => {
  
  let helpWith = props.dataItem.helpWith;
  if ( helpWith && helpWith.length > 100){
    helpWith = true;
  }else {
    helpWith = false;
  }
    
  const icon = helpWith ? (
    <span className="k-icon k-i-sort-desc-sm" />
  ) : (
    <span className="k-icon k-i-sort-asc-sm" />
  );

  const style1 = {
        overflow: "hidden",
        maxHeight: "60px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        backgroundColor: "rgb(243, 23, 0, 0.32)"
  };
  const style2 = {backgroundColor: "rgb(55, 180, 0,0.32)"};
  const style = helpWith ? style1 : style2;
    
  const field = props.field || "";

  return (
    <td style={style}>
      {props.dataItem[field]} {icon}
    </td>
  );
};

const resizable = true;

const labelContent = (props) => {
 
  let formatedNumber = Number(props.dataItem.leads).toLocaleString(undefined, {
    style: "percent",
    minimumFractionDigits: 2,
  });
  return `${props.dataItem.contactLocation}  ${formatedNumber}`;
  
};

const leadStatusLabelContent = (props) => {
 
  let formatedNumber = Number(props.dataItem.leads).toLocaleString(undefined, {
    style: "percent",
    minimumFractionDigits: 2,
  });
  return `${props.dataItem.leadStatus}  ${formatedNumber}`;
  
};

let contactLocationSeries = [];
let setContactLocationSeries = ( contactLocationCount, totalUsers ) => {
 
  const n = contactLocationCount.map( (object, index, array) => {
      return { "leads" : (object.users / totalUsers),
               "contactLocation": object.contactLocation,
             
              }

  });
  contactLocationSeries = [...n];

}
let leadStatusSeries = [];
let setLeadStatusSeries = ( leadStatusCount, totalUsers ) => {
 
  const n = leadStatusCount.map( (object, index, array) => {
      return { "leads" : (object.users / totalUsers),
               "leadStatus": object.leadStatus,
              
              }

  });
  leadStatusSeries = [...n];
}

let formIdSeries = [];
let setFormIdSeries = ( formIdCount, totalUsers ) => {
 
  const n = formIdCount.map( (object, index, array) => {
                 
      return { "name" : object.formId, "data": [object.users], }; //array required with map in griditem //categories are on the side with color scheme
  });
  
  formIdSeries = [...n];
 
 
}

let formIdCounts = [];
let formIdLabels = [];
let setFormIdData = ( countArray ) => {

  formIdLabels.length = 0;
  formIdCounts.length = 0;

  for( let ix=0; ix< countArray.length; ix++){
    formIdLabels.push( countArray[ix].formId );
    formIdCounts.push( countArray[ix].users);
  }
  
}

const pagerSettings = {
  buttonCount: 5,
  info: true,
  //type: "numeric",
  type: "input",
  //pageSizes: true,
  pageSizes: [5, 10, 20, 50, 100, 200],
  previousNext: true,
};

const initialDataState = {
  skip: 0,
  take: 10,
};

const dayMilis = 24 * 60 * 60 * 1000;

const KendoGridDateCell = (props) => {
  const value = props.dataItem[props.field];
  return (
        <td> 
          {formatDate(new Date(value), "d")}
        </td>
    );
}

const KendoGridPhoneCell = (props) => {
  const value = props.dataItem[props.field];
  return (
        <td> 
          {value.replace(/(\d{3})(\d{3})(\d{4})/,"$1-$2-$3")}
        </td>
    );
}

export const Query = (props) => {

  let [items, setItems] = React.useState([]);

  const [fromDate, setFromDate] = useState(new Date(Date.now() - (7 * dayMilis )));
  const [toDate, setToDate] = useState( new Date(Date.now() + (1 * dayMilis )) );
  const [website, setWebsite] = useState({dallas: true, plano : true});
  const [formId, setFormId] = useState("all");
  const [leadStatus, setLeadStatus] = useState({convertedLead: true, webForm : true, notApplicable : false});
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  
  const [totalItems, setTotalItems] = useState(0);
 
  const [currentSearchPage, setCurrentSearchPage] = useState(0);
  const [page, setPage] = React.useState(initialDataState);
  const [search, setSearch] = useState(null);
  const [redirecting, setRedirecting] = useState(false);
  const [message, setMessage] = useState("");
    
  const [loading, setLoading] = useState(false);
 
  const _grid = React.useRef();

  const [editId, setEditId] = useState(null);
  const [editField, setEditField] = React.useState(undefined);
  const [changes, setChanges] = React.useState(false);

  const [toggle, setToggle] = useState(false); 


  const enterEdit = (dataItem, field) => {
    const newData = items.map((item) => ({
      ...item,
      inEdit: item.id === dataItem.id ? field : undefined,
    }));
    setItems(newData);
    setEditField(field);
    
  };

  const exitEdit = () => {
    const newData = items.map((item) => ({ ...item, inEdit: undefined }));
    setItems(newData);
    setEditField(undefined);
  };

  

  const saveChanges = () => {
   
    if ( !validateLeadStatus( editId ) ){
      setMessage("Invalid lead status. Must be one of 'not applicable', 'web form' 'converted lead'");
      return;
    }
    
    QueryService.updateForms(items)
        .then((res) => {
          //console.log( "update res " + JSON.stringify(res) );
     
        })
        .catch((error) => {
          console.log("Error: ", JSON.stringify(error));
          if (error.response && error.response.status === 401) {
            AuthService.logout();
            props.updateUser(undefined);
            props.updateRedirecting(true);
          }
          const err = ( error.response && error.response.data && error.response.data.message ) ||
                        error.message ||
                        error.toString();
          //props.updateMessage(err);
        });
   
    setEditField(undefined);
    setChanges(false);

  };

  const cancelChanges = () => {
    
    setChanges(false);
  };

  const itemChange = (event) => {
   
    let field = event.field || "";
    event.dataItem[field] = event.value;
    let newData = items.map((item) => {
      if (item.id === event.dataItem.id) {
        item[field] = event.value;
      }
      return item;
    });
  
    setEditId( event.value );
    setItems(newData);
    setChanges(true);
  };

  const customCellRender = (td, props) => (
    <CellRender
      originalProps={props}
      td={td}
      enterEdit={enterEdit}
      editField={editField}
    />
  );

  const customRowRender = (tr, props) => (
    <RowRender
      originalProps={props}
      tr={tr}
      exitEdit={exitEdit}
      editField={editField}
    />
  );
  
  const closeEdit = (event) => {
    if (event.target === event.currentTarget) {
      setEditId(null);
    }
  };
   

  const pageChange = (event) => {
       
    if (search !== null) {
     
      const currentPage = Math.floor(event.page.skip / event.page.take); //start from 0 page for the search
      setCurrentSearchPage(currentPage);
      search.currentPage = currentPage;
      search.pageSize = event.page.take;
           
      QueryService.queryAllForms(search)
        .then((res) => {
          setItems(res.data.items);
          setContactLocationSeries( res.data.contactLocationCount, res.data.totalItems );
          setLeadStatusSeries( res.data.leadStatusCount, res.data.totalItems );
          setFormIdSeries( res.data.formIdCount, res.data.totalItems );
          setFormIdData( res.data.formIdCount );
          setTotalItems(res.data.totalItems);
          setPage(event.page);
                
        })
        .catch((error) => {
          console.log("Error: ", JSON.stringify(error));
          if (error.response && error.response.status === 401) {
            AuthService.logout();
            props.updateUser(undefined);
            props.updateRedirecting(true);
          }
          const err = ( error.response && error.response.data && error.response.data.message ) ||
                        error.message ||
                        error.toString();
          //props.updateMessage(err);
        });
    }


  };
  

  const resetData = () => {
 
    items.length = 0;
    setTotalItems(0);
    setFormIdData([]);
    setCurrentSearchPage(0);
   
  }

  const onChangeFormId = (e) => {
    const formId = e.target.value;
    setFormId(formId);
    resetData();
  };

  const onChangeWebsite = (e) => {
    //console.log(`e.target.checked ${e.target.checked}` );
    setWebsite( ws => ({ ...ws, [e.target.name]: !ws[e.target.name] }));
    
    resetData();
  };

  const onChangeLeadStatus = (e) => {
    setLeadStatus( ls => ({ ...ls, [e.target.name]: !ls[e.target.name] }));
    resetData();
  };

  const onChangeFirstName = (e) => {
    const firstName = e.target.value;
    setFirstName(firstName);
    resetData();
  };

  const onChangeLastName = (e) => {
    setLastName(e.target.value);
    resetData();
  };

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
    resetData();
  };

  const onChangePhone = (e) => {
    setPhone( e.target.value );
    resetData();
  };
  
  const validateAll = () =>{

    if( (!website.dallas && !website.plano )){
      setMessage("Please select website.");
      return false;
    }

    if( (!leadStatus.convertedLead && !leadStatus.webForm && !leadStatus.notApplicable )){
      setMessage("Please select lead status.");
      return false;
    }
    
    setMessage("");
    return true;
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    if ( !validateAll() ){ return; }
   
    setSearch({

      fromDate: fromDate,
      toDate: toDate,
      formId: formId,
      website: website,
      leadStatus: leadStatus,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      currentPage: currentSearchPage,
      pageSize: page.take,
    });
  };

  useEffect(() => {
    if (search !== null) {
   
      QueryService.queryAllForms(search)
        .then((res) => {
          setItems(res.data.items);
          setContactLocationSeries ( res.data.contactLocationCount, res.data.totalItems );
          setLeadStatusSeries( res.data.leadStatusCount, res.data.totalItems );
          setFormIdSeries( res.data.formIdCount, res.data.totalItems );
          setFormIdData( res.data.formIdCount );
          setTotalItems(res.data.totalItems);
             
        })
        .catch((error) => {
          console.log("Error: ", JSON.stringify(error));
          if (error.response && error.response.status === 401) {
            AuthService.logout();
            props.updateUser(undefined);
            setRedirecting(true);
          }
          const err = ( error.response && error.response.data && error.response.data.message ) ||
                        error.message ||
                        error.toString();
          setMessage(err);
        });
    }
    

  }, [search]);
 
  return (
    <div className="container-fluid">
      <Form onSubmit={handleSubmit} >
        <div className="row tg-query form-group">
          <div className="col-sm">
            <label className="mdb-main-label">From</label>
            <DatePicker
              selected={fromDate}
              onChange={(date) => setFromDate(date)}
            />
          </div>
          <div className="col-sm">
            <label className="mdb-main-label">To</label>
            <DatePicker
              selected={toDate}
              onChange={(date) => setToDate(date)}
            />
          </div>
          <div className="col-sm">
            <label>Website</label>
            <div className="checkbox">
                <label><input type="checkbox" key="dallas" name="dallas" checked={website.dallas} onChange={onChangeWebsite}></input> Dallas</label>
            </div>
            <div className="checkbox">
                <label><input type="checkbox" key="plano" name="plano" checked={website.plano} onChange={onChangeWebsite}></input> Plano</label>
            </div>
          </div>       
          <div className="col-sm">
            <label className="mdb-main-label">Form</label>
            <select
              className="form-control"
              name="formId"
              value={formId}
              onChange={onChangeFormId}
            > { website.dallas === true && website.plano === true ? 
                 allForms.map(([value, text]) => (<option key={value} value={value}>{text}</option>)) : "" }
              { website.dallas === true && website.plano === false ?
                 dallasForms.map(([value, text]) => (<option key={value} value={value}>{text}</option>)) : ""}
              { website.dallas === false && website.plano === true ? 
                planoForms.map(([value, text]) => (<option key={value} value={value}>{text}</option>)) : "" }
                       
            </select>
          </div>
          <div className="col-sm">
            <div className="checkbox">
                <label><input type="checkbox" key="convertedLead" name="convertedLead" checked={leadStatus.convertedLead} onChange={onChangeLeadStatus}></input> Converted lead</label>
            </div>
            <div className="checkbox">
                <label><input type="checkbox" key="webForm" name="webForm" checked={leadStatus.webForm} onChange={onChangeLeadStatus}></input> Web form</label>
            </div>
            <div className="checkbox">
                <label><input type="checkbox" key="notApplicable" name="notApplicable" checked={leadStatus.notApplicable} onChange={onChangeLeadStatus}></input> Not applicable</label>
            </div>
          </div>       
        </div>
        <div className="row tg-query form-group">
          <div className="col-sm" style={{paddingBottom: "10px"}}>
            <label className="mdb-main-label">First Name</label>
            <input type="text" value={firstName} onChange={onChangeFirstName}></input>
          </div>
          <div className="col-sm">
            <label className="mdb-main-label">Last Name</label>
            <input type="text" value={lastName} onChange={onChangeLastName}></input>
          </div>
          <div className="col-sm">
            <label className="mdb-main-label">Email</label>
            <input type="email" value={email} onChange={onChangeEmail}></input>
          </div>
          <div className="col-sm">
            <label className="mdb-main-label">Phone</label>
            <input type="tel" value={phone} placeholder="123-456-7890" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" onChange={onChangePhone}></input>
          </div>      
          <div className="col-sm">
          </div>
        </div>
        <div className="row justify-content-center">
          <button type="submit" className="btn btn-primary" >Submit</button>
        </div>
        <div className="row">
          {/*<div className={"alert alert-danger" } role="alert"></div> */}
          <p style={{color: "red"}}> {message}</p>
        </div>
        {(totalItems > 0) ?
        <>
          <div className="row justify-content-md-end">
            <button type="button" className="btn btn-primary fa fa-download" 
                    disabled={loading}
                    onClick={ ()=> QueryService.downloadCsv(search)}> Download
            </button>       
          </div>
          <hr style={{paddingBottom: "20px"}}></hr>
        </>: ""}
        <Grid 
              data={items}
              onItemChange={itemChange}
              onPageChange={pageChange}
                        
              rowHeight={50}
              cellRender={customCellRender}
              rowRender={customRowRender}
              editField="inEdit"

              total={totalItems}
              skip={page.skip}
              take={page.take}
              pageable={pagerSettings}
              resizable={resizable}
              sortable={true}
              ref={_grid}
          >
            <GridToolbar>
              <button
                title="Save Changes"
                className="k-button"
                onClick={saveChanges}
                disabled={!changes}
              > Save Changes </button>
              <button
                title="Cancel Changes"
                className="k-button"
                onClick={cancelChanges}
                disabled={!changes}
              > Cancel Changes </button>
            
            </GridToolbar>
            <Column field="id"  width="70px" editable={false}/>
            <Column field="formId" title="Form" width="60px" editable={false}/>
            <Column field="dateCreated" title="Entry Date" cell={KendoGridDateCell} width="100px" editable={false}/>
            <Column field="firstName" title="First Name" width="120px" editable={false}/>
            <Column field="lastName" title="Last Name" width="120px" editable={false}/>
            <Column field="email" title="Email" width="130px" editable={false}/>
            <Column field="phone" title="Phone" width="130px" cell={KendoGridPhoneCell} editable={false}/>
            <Column field="foundUsHow" title="How did you find us?" width="120px" editable={false}/>
            <Column field="contactLocation" title="Location" width="120px" editable={false}/>
            <Column field="leadStatus" title="Lead Status" width="90px" editor="text"/>
            <Column field="sourceUrl" title="Source Url" width="120px" editable={false}/>
            <Column field="helpWith" title="How can we help you?" editable={false} cell={ShowMoreCell}/>
        </Grid>
             
        {(totalItems > 0 && formId === "all" ) ?
        <>
          <hr style={{paddingBottom: "20px"}}></hr>
          <div className="row">
            <div className="col-sm-6">
            <Chart>
                <ChartTitle text="Total Leads by Type" />
                {/*<ChartTooltip format="{0}" /> */}
                <ChartLegend position="bottom" />
                <ChartSeries>
                  <ChartSeriesItem
                    type="pie"
                    data={leadStatusSeries}
                    field="leads"
                    categoryField="leadStatus"
                    labels={{
                      visible: true,
                      content: leadStatusLabelContent,
                    }}
                    align="column"
                  />
                </ChartSeries>
              </Chart>   
             
            </div>
            <div className="col-sm-6">
              <Chart>
                <ChartTitle text="Total Leads by Contact Location" />
                {/*<ChartTooltip format="{0}" /> */}
                <ChartLegend position="bottom" />
                <ChartSeries>
                  <ChartSeriesItem
                    type="pie"
                    data={contactLocationSeries}
                    field="leads"
                    categoryField="contactLocation"
                    labels={{
                      visible: true,
                      content: labelContent,
                    }}
                    align="column"
                  />
                </ChartSeries>
              </Chart>
            </div>
          </div>
          <hr style={{paddingBottom: "20px"}}></hr>
          <div className="row">
            <div className="col-sm-2">
            </div>
            <div className="col-sm-6">
              <Chart>
                <ChartTitle text="Total Leads By Web Form" />
                  <ChartSeries>
                    {formIdSeries.map((item, idx) => (
                    <ChartSeriesItem
                      type="bar"
                      key={idx}
                      data={item.data}
                      name={item.name}
                      tooltip={{
                        visible: true,
                      }}
                    />
                    ))}
                </ChartSeries>
              </Chart>
            </div>
            <div className="col-sm-4">
            </div>
          </div>
          <hr style={{paddingBottom: "20px"}}></hr>      
        </>
         : ""
        }
                
      </Form>
      
      { redirecting ? <Redirect to="/login" /> : null }
    
    </div>
    
  );
};
