import React, { useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import Form from "react-validation/build/form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import AuthService from "../services/auth.service";
import ImportService from "../services/import.service";
import "../App.css";
import LoadingSpinner from "../components/LoadingSpinner";

let items = {};
const setItems = (newItems) => {
  items = newItems;
}
const tomorrow = new Date();
tomorrow.setDate(new Date().getDate() + 1);

const ImportWebForms = (props) => {
  
  const [fromDate, setFromDate] = useState(new Date("June 1, 2021"));
  const [toDate, setToDate] = useState(tomorrow);
  const [search, setSearch] = useState(null);
  const [importing, setImporting] = useState(false);
  const [redirecting, setRedirecting] = useState(false);
  const [message, setMessage] = useState("");
  
  
  const handleSubmit = (e) => {
    
    e.preventDefault();
    setSearch({
      fromDate: fromDate,
      toDate: toDate,
    });
  };

  useEffect(() => {
    if (search !== null) {
      setImporting(true);
      ImportService.importAllForms(search)
        .then((res) => {
          //console.log("1Success response: ", JSON.stringify( res.data ));
          setItems(res.data);
          setImporting(false);
                            
        })
        .catch((error) => {
          setImporting(false);
          console.log("Error: ", JSON.stringify(error));
          if (error.response && error.response.status === 401) {
            AuthService.logout();
            props.updateUser(undefined);
            setRedirecting(true);
          }
          const err = ( error.response && error.response.data && error.response.data.message ) ||
                        error.message ||
                        error.toString();
          setMessage(err);
        });
    }
  
  }, [search]);

  
  return (
    <div className="container">
      <div className="row justify-content-center" style={{ backgroundColor: "#e9ecef"}}>
        Import Web Forms
      </div>
      
      <Form onSubmit={handleSubmit} >
        
        <div className="row justify-content-center" style={{ backgroundColor: "#e9ecef"}}>
            <div className="col-sm vertical-center">
              <label className="mdb-main-label">From Date</label>
              <DatePicker
                selected={fromDate}
                onChange={(date) => setFromDate(date)}
              />
            </div>
            <div className="col-sm vertical-center">
              <label className="mdb-main-label">To Date</label>
              <DatePicker
                selected={toDate}
                onChange={(date) => setToDate(date)}
              />
            </div>
            <div className="col-sm vertical-center">
              <label className="mdb-main-label"  style={{ visibility: "hidden" }} >   aaa   </label>
              <div style={{ marginLeft: "00px" }} > 
                <button type="submit" className="btn btn-primary btn-sm" disabled={ importing === true }>Submit</button> 
              </div>
            </div>            
      
        </div>
        <div className="row justify-content-center">
           
            {importing ? <LoadingSpinner /> : 
              <div className="col-sm vertical-center">
                {Object.keys(items).map((key, i) => (
                    <><div className="row justify-content-center">
                        <div className="col-sm">{key}</div>
                        <div className="col-sm">{items[key]}</div>
                      </div>
                    </>
                  ))}
            </div>}
        </div>
        <div className="row">
          {/*<div className={"alert alert-danger" } role="alert"></div> */}
          <p style={{color: "red"}}> {message}</p>
        </div>

      </Form>
      
      { redirecting ? <Redirect to="/login" /> : null }
    
    </div>
    
  );
};
export default ImportWebForms;
