import axios from "axios";
import AuthService from "./auth.service";
import fileDownload from 'js-file-download'
import { QUERY_ALL_FORMS_API, EXPORT_FORMS_API, UPDATE_FORMS_API } from "./constants"

const updateForms = (items) => {
  return axios({
    url: UPDATE_FORMS_API,
    method: "post",
    data: items,  
    headers: {
      Authorization: AuthService.authHeader(),
      Accept: "application/json",
    },
  });
  
};

const queryAllForms = (search) => {
  
  let phone = search.phone.replace(/-/g, "");
  
  let data = {  
      formId: search.formId,
      website: search.website,
      leadStatus: search.leadStatus,
      firstName: search.firstName,
      lastName: search.lastName,
      email: search.email,
      phone: phone,
  };

  return axios({
    url: QUERY_ALL_FORMS_API,
    method: "post",
    params: {
      fromDate: search.fromDate,
      toDate: search.toDate,
      currentPage: search.currentPage,
      pageSize: search.pageSize
    },
    data: data,  
    headers: {
      Authorization: AuthService.authHeader(),
      Accept: "application/json",
    },
  });
  
};


const extractFileName = ( contentDispositionValue ) => {
  let filename = "";
  if (contentDispositionValue && contentDispositionValue.indexOf('attachment') !== -1) {
      let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      let matches = filenameRegex.exec(contentDispositionValue);
      if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
      }
  }
  console.log( "csv filename " + filename);
  return filename;
}

const downloadCsv = ( search ) => {

  let phone = search.phone.replace(/-/g, "");
  let data = {  
    formId: search.formId,
    website: search.website,
    leadStatus: search.leadStatus,
    firstName: search.firstName,
    lastName: search.lastName,
    email: search.email,
    phone: phone,
  };
     
  let config = {
    method: "post",
    url: EXPORT_FORMS_API,
    params: {
      fromDate: search.fromDate,
      toDate: search.toDate,
    },
    data: data,
    headers: {
      Authorization: AuthService.authHeader(),
    },
  };
      
  axios(config)
    .then(function (response) {
      let filename = extractFileName(response.headers['content-disposition']);
      fileDownload(response.data, filename);
    
    }).catch( (error) => {
      console.log(error);
      if (error.response) {
        console.log('Error downloadCsv ', error.response.status);
      } else {
        console.log('Error downloadCsv ', error.message);
      }
  });
  
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  queryAllForms,
  updateForms,
  downloadCsv
};
