import React from 'react';

const LoadingSpinner = () => (
    <div>
       <br></br>
        <i className="fa fa-spinner fa-spin fa-lg" /><span style={{marginLeft: "0.5em"}}>Importing DWLC web forms...</span>
    </div>
);

export default LoadingSpinner;
