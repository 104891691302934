import React, { useState, useEffect, useRef } from "react";

const breakPos = 60;

export const ShowMoreCell = (props) => {
  const [readMore, setReadMore] = useState( false );
  const field = props.field || "";
  const [readMoreStyle, setReadMoreStyle] = useState("inline");
  const [text2Style, setText2Style] = useState("none");
  const [readLessStyle, setReadLessStyle] = useState("none");

  const toggleReadMore = () => {
      
    if( readMore === true ){
      setReadMoreStyle("none");
      setText2Style("inline");
      setReadLessStyle("inline");
    }else{
      setReadMoreStyle("inline");
      setText2Style("none");
      setReadLessStyle("none");
    }
    setReadMore(!readMore);
    
  };
  
  const showReadMore = ( text ) => {
      
    return <>
            <div id="text1"> { text.slice(0, breakPos)} <span id="readMore" style={{display: `${readMoreStyle}`}}><b>...</b><span className="k-icon k-i-sort-desc-sm"></span></span></div>
            <div id="text2" style={{display: `${text2Style}`}}> { text.slice(breakPos) } <span id="readLess" style = {{display: `${readLessStyle}`}} ><b>...</b><span className="k-icon k-i-sort-asc-sm"></span></span></div>
          </>
  }

  return (
    <td onClick={() => toggleReadMore(props.dataItem[field])} >
       { props.dataItem[field] !== undefined && props.dataItem[field].length > 60 ? showReadMore(props.dataItem[field]) : props.dataItem[field] }
    </td>
  );

 
};