import React from "react";
import { Upload } from '@progress/kendo-react-upload';
import AuthService from "../services/auth.service";
import { UPLOAD_NEW_PATIENT_API } from "../services/constants"

const UploadPatients = (props) => {
  
  const [file, setFiles] = React.useState([]);

  const onBeforeUpload = (event) => {
    event.headers = { "Authorization": AuthService.authHeader() };
  };

  const onAdd = (event) => {
    setFiles(event.newState);
  };

  const onRemove = (event) => {
    setFiles(event.newState);
  };

  const onProgress = (event) => {
    setFiles(event.newState);
  };

  const onStatusChange = (event) => {
    setFiles(event.newState);
  };

  
return (
    
  <div className="container">
    <div className="row ">
      <div className="col-sm" style={{margin:"20px"}}>
        Select an Excel file with new DWLC patients to upload. 
        Uploaded patients will be marked as converted leads if a web forms match is found.
        
      </div>
    </div>
    <div className="row ">
      <div className="col-sm">
              <Upload
                batch={false}
                multiple={true}
                files={file}
                saveField='file'
                defaultFiles={[]}
                autoUpload={false}
                withCredentials={true}
                actionsLayout="center"
                restrictions={{
                  allowedExtensions: [".xlsx"],
                }}
                onBeforeUpload={onBeforeUpload}
                onAdd={onAdd}
                onRemove={onRemove}
                onProgress={onProgress}
                onStatusChange={onStatusChange}
                saveUrl={UPLOAD_NEW_PATIENT_API}
              />
      </div>
    </div>
  </div>    

  );
}
export default UploadPatients;
