import axios from "axios";
import AuthService from "./auth.service";
import {IMPORT_ALL_FORMS_API} from "./constants"

const importAllForms = (search) => {
 
  return axios({
    url: IMPORT_ALL_FORMS_API,
    method: "get",
    params: {
      fromDate: search.fromDate,
      toDate: search.toDate
    },
    headers: {
      Authorization: AuthService.authHeader(),
      Accept: "application/json",
    },
  });
  
};

export default {
  importAllForms,

};
