import axios from "axios";
import {AUTH_API, TG_DWLC_JWT} from "./constants"


const login = (username, password) => {
  
  return axios
    .post(AUTH_API + "login", {
      username,
      password,
    })
    .then((response) => {
      if (response.data) {
        localStorage.setItem(TG_DWLC_JWT, JSON.stringify(response.data));
      }
      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem(TG_DWLC_JWT);
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem(TG_DWLC_JWT));
};

const register = (username, email, password) => {
  return axios.post(AUTH_API + "signup", {
    username,
    email,
    password,
  });
};

const authHeader = () => {

  const user = JSON.parse(localStorage.getItem(TG_DWLC_JWT));
  if (user) {
    let header = "Bearer " + user;
    return header;
  } else {
    return {};
  }
}

export default { authHeader, login, logout, getCurrentUser  };
