import React, { useState, useEffect, Image } from "react";
import { Switch, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
//import '@progress/kendo-theme-default/dist/all.css';
import '@progress/kendo-theme-bootstrap/dist/all.css';
import "./App.css";

import AuthService from "./services/auth.service";

import Login from "./pages/Login";
import Home from "./pages/Home";
import UploadPatients from "./pages/UploadPatients";
import ImportWebForms from "./pages/ImportWebForms";
import {Query} from "./pages/Query";
import {Redirect } from "react-router-dom";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      AuthService.getCurrentUser() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);
const App = () => {
  const [currentUser, setCurrentUser] = useState(undefined);

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (user) {
      setCurrentUser(user);
    }
  }, []);

  const logOut = () => {
    AuthService.logout();
  };

  const handleToUpdateUser = ( user ) => {
    setCurrentUser( user );
  };

  return (
    <div>
      <nav className="navbar navbar-expand navbar-dark tg-blue-bg ">
        <div className="navbar-nav mr-auto">
          <li className="nav-item">
            <Link to={"/home"} className="nav-link nav-link-color">
              Home
            </Link>
          </li>
          {currentUser && (
            <li className="nav-item">
              <Link to={"/import-web-forms"} className="nav-link nav-link-color">
                Import Web Forms
              </Link>
            </li>   )}
          {currentUser && (  
            <li className="nav-item">
              <Link to={"/upload-patients"} className="nav-link nav-link-color">
                Upload Patients
              </Link>
            </li> )}
          {currentUser && (    
            <li className="nav-item">
              <Link to={"/query"} className="nav-link nav-link-color">
                Query
              </Link>
            </li>  )}         
        </div>

        {currentUser ? (
          <div className="navbar-nav ml-auto">
            <li className="nav-item">
              <a href="/logout" className="nav-link" onClick={logOut}>
                LogOut
              </a>
            </li>
          </div>
        ) : (
          <div className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link to={"/login"} className="nav-link">
                Login
              </Link>
            </li>
          </div>
        )}
      </nav>

      <div className="container-fluid mt-3">
        <Switch>
          <Route exact path={["/", "/home"]} component={Home} />
          <Route exact path="/login" component={Login} />
          <Route path="/import-web-forms" render={(props) => AuthService.getCurrentUser() ? (<ImportWebForms {...props} updateUser={handleToUpdateUser} />) : 
              ( <Redirect to={{pathname: "/login", state: { from: props.location }, }} /> ) }
          />
          <Route path="/upload-patients" render={(props) => AuthService.getCurrentUser() ? (<UploadPatients {...props} updateUser={handleToUpdateUser} />) : 
              ( <Redirect to={{pathname: "/login", state: { from: props.location }, }} /> ) }
          />
          <Route path="/query" render={(props) => AuthService.getCurrentUser() ? (<Query {...props} updateUser={handleToUpdateUser} />) : 
              ( <Redirect to={{pathname: "/login", state: { from: props.location }, }} /> ) }
          />
        </Switch>
      </div>
    </div>
  );
};

export default App;
