import React, { useState, useEffect } from "react";

const homeContent = () => {

  return (
    <div className="container">
     <div className="row justify-content-center" >
        <ol>
          <li>Login</li>
          <li>Import Web Forms</li>
          <li>Upload New Patients</li>
          <li>Query & Charts</li>
        </ol>
      </div>
    </div>
    );
}

const Home = () => {
  const [content, setContent] = useState("");

  useEffect(() => {
    setContent(homeContent);
  }, []);
 

  return (
    <div className="container">
      <header className="jumbotron">
        {content}
      </header>
    </div>
  );
};

export default Home;
